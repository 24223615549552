<div
  *ngIf="!(contactHistories$ | async)?.length"
  class="card card-light mb-4 m-0"
>
  <div class="card-body text-center text-primary">
    <div>Không có dữ liệu hiển thị</div>
    <i class="fs-24 mdi mdi-database-off-outline"></i>
  </div>
</div>

<div class="d-flex justify-content-start align-items-center">
  <table class="table table-striped">
    <thead>
      <tr>
        <th *ngFor="let item of SUB_COLUMNS" scope="col">
          {{ item.field }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let history of contactHistories$ | async">
        <tr>
          <ng-container
            *ngTemplateOutlet="content; context: { $implicit: history }"
          ></ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #headers>
  <th class="gridjs-th gridjs-th-sort gridjs-th-fixed">Tiêu đề</th>
  <th class="gridjs-th gridjs-th-sort gridjs-th-fixed" data-sort="position">
    Nội dung
  </th>
  <th
    class="bg-light gridjs-th gridjs-th-sort gridjs-th-fixed"
    data-sort="name"
  >
    Người gọi
  </th>
  <th
    class="bg-light gridjs-th gridjs-th-sort gridjs-th-fixed"
    data-sort="email"
  >
    Người khác thác MB
  </th>
  <th
    class="bg-light gridjs-th gridjs-th-sort gridjs-th-fixed"
    data-sort="position"
  >
    SĐT Chủ nhà
  </th>
  <th
    class="bg-light gridjs-th gridjs-th-sort gridjs-th-fixed"
    data-sort="country"
  >
    Bắt đầu
  </th>
  <th
    class="bg-light gridjs-th gridjs-th-sort gridjs-th-fixed"
    data-sort="country"
  >
    Kết thúc
  </th>
  <th
    class="bg-light gridjs-th gridjs-th-sort gridjs-th-fixed"
    data-sort="country"
  >
    Thời gian
  </th>
</ng-template>

<ng-template #content let-data>
  <td scope="row">
    <div class="history-title truncate-text" [ngbTooltip]="data?.title">
      {{ data?.title }}
    </div>
  </td>
  <td>
    <div class="truncate-text" [ngbTooltip]="data?.content">
      {{ data?.content }}
    </div>
  </td>
  <td class="position-relative">{{ data?.caller }}</td>
  <td>{{ data?.propertyManager }}</td>
  <td>
    {{ data?.phoneNumber | invisiblePhoneNumber | async }}
  </td>
  <td>
    <button ngbTooltip="Bấm để nghe" class="btn btn-sm btn-outline-success">
      <i class="ri-volume-up-line fs-18 me-1"></i>
    </button>
  </td>
  <td>{{ data?.propertyStatusBefore | getFullStatus }}</td>
  <td>{{ data?.propertyStatusAfter | getFullStatus }}</td>
  <td>{{ data?.method.name }}</td>
  <td>{{ data?.statusCall.name }}</td>
  <!--  <td>{{ data?.createdAt | timeAgo }}</td>-->

  <td>
    {{ data?.startTime | date: " HH:mm" }}-{{ data?.endTime | date: " HH:mm" }}
  </td>
  <td>{{ data?.endTime | date: "MM-dd-yy" }}</td>

  <!--  <td>{{ data?.endTime | date: "EEEE, d MMMM, y" }}</td>-->
</ng-template>

<ng-template>
  <audio controls>
    <source src="horse.ogg" type="audio/ogg" />
    <source src="horse.mp3" type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
</ng-template>
