import { Component, inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-waiting-loading-modal",
  templateUrl: "./waiting-loading-modal.component.html",
})
export class WaitingLoadingModalComponent {
  public activeModal = inject(NgbActiveModal)
}
