import { Directive, ElementRef, HostListener, OnInit } from "@angular/core";
import { USER_INFO } from "@constant/common.constant";
import { GroupModel } from "@model/group.model";
import { StorageService } from "@service/storage/storage.service";
import { maskPhoneNumber } from "@util/hidden-phone-number";
import { AuthorizationService } from "@service/auth/authorization.service";

@Directive({
  standalone: true,
  selector: "[invisiblePhoneNumber]",
})
export class InvisiblePhoneNumberDirective implements OnInit {
  public phoneNumber: string;

  constructor(
    private el: ElementRef,
    private authorization: AuthorizationService,
    private storageService: StorageService
  ) {}

  @HostListener("input", ["$event"])
  onInputChange($event: Event) {
    this.phoneNumber = ($event?.target as HTMLInputElement).value;
  }

  ngOnInit(): void {
    this.phoneNumber = this.el.nativeElement.value;
    this.handleHiddenNumber(this.phoneNumber);
  }

  @HostListener("blur", [])
  onBlur() {
    this.enabledMaskPhone("blur");
  }

  @HostListener("window:click", ["$event"])
  onClick($event: Event) {
    const target = $event?.target as HTMLInputElement;
    if (target.id === "save-edit-real-estate") {
      this.enabledMaskPhone("submit");
    }
  }

  public enabledMaskPhone(event: string) {
    if (event === "blur") this.handleHiddenNumber(this.phoneNumber);
  }

  private handleHiddenNumber(value: string) {
    const hasPermission = this.authorization.isSuperAdmin();
    const groups = this.getUserGroup();

    const notHiddenPhone = groups.some(
      (group: GroupModel) => group.id === 2 || group.id === 4
    );

    if (!value) {
      this.el.nativeElement.value = "";
      return;
    }

    this.el.nativeElement.value = maskPhoneNumber(value);
    if (hasPermission || notHiddenPhone) this.el.nativeElement.value = value;
  }

  private getUserGroup() {
    const userGroups = this.storageService.get(USER_INFO).groups;

    return userGroups ? userGroups : [];
  }
}
