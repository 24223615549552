<ng-container
  *ngIf="stateContactHistory.historyCallOrders$ | async as contactHistories"
>
  <div *ngIf="!contactHistories?.length" class="card card-light mb-4 m-0">
    <div class="card-body text-center text-primary">
      <div>Không có dữ liệu hiển thị</div>
      <i class="fs-24 mdi mdi-database-off-outline"></i>
    </div>
  </div>

  <div
    class="d-flex justify-content-start align-items-center"
    *ngIf="contactHistories?.length"
  >
    <table class="table table-striped">
      <thead>
        <tr>
          <th *ngFor="let item of COLUMNS" scope="col">
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let history of contactHistories">
          <tr>
            <ng-container
              *ngTemplateOutlet="content; context: { $implicit: history }"
            ></ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-template #content let-data>
  <td scope="row">
    <div class="text-truncate" [ngbTooltip]="data?.title">
      <span>
        {{ data?.title }}
      </span>
    </div>
  </td>
  <td>
    <div class="truncate-text" [ngbTooltip]="data.content">
      <span class="content-w d-block">
        {{ data?.content }}
      </span>
    </div>
  </td>
  <td>{{ data?.caller }}</td>
  <td>
    {{ data?.phoneNumber | invisiblePhoneNumber | async }}
  </td>
  <td>
    <button ngbTooltip="Bấm để nghe" class="btn btn-sm btn-outline-success">
      <i class="ri-volume-up-line fs-18 me-1"></i>
    </button>
  </td>
  <td>{{ data?.method.name }}</td>
  <td>{{ data?.statusCall.name }}</td>

  <td>
    {{ data?.startTime | date: " HH:mm" }} -
    {{ data?.endTime | date: " HH:mm" }}
  </td>
  <td>{{ data?.endTime | date: "dd/MM/YYYY" }}</td>
</ng-template>

<ng-template>
  <audio controls>
    <source src="horse.ogg" type="audio/ogg" />
    <source src="horse.mp3" type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
</ng-template>
