import { inject, Injectable, Injector } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  CallOrderModel,
  CallRealEstateModel,
} from "@component/call-history-form-to-owner/call-history-container.component";
import { handleTitle } from "@component/call-history-form-to-owner/containers/call-history-form/call-history-form.helper";
import { USER_INFO } from "@constant/common.constant";
import { CustomerModel } from "@model/customer.model";
import { ContactModel } from "@model/order.model";
import { RealEstateModel } from "@model/real-estate.model";
import { StorageService } from "@service/storage/storage.service";
import { ConvertDateTimeToString } from "@util/date-time.util";

@Injectable()
export class RealEstateHistoryFormHelper {
  private readonly formGroup: FormGroup;
  private fb = inject(FormBuilder);
  private injector = inject(Injector);
  private storageService = inject(StorageService);

  constructor() {
    this.formGroup = this.initForm();
  }

  get form() {
    return this.formGroup as FormGroup;
  }

  patchForm(data: any) {
    const customer = (data as CallRealEstateModel)?.customers?.find(
      (c: CustomerModel) => {
        return c.address.find((add) => add?.phone === data?.numberToCall);
      }
    );

    const contact = (data as CallOrderModel)?.contacts?.find(
      (c: ContactModel) => c?.phone === data?.numberToCall
    );

    const phones = customer
      ? customer.address.map((add) => add?.phone)
      : contact
      ? [contact.phone]
      : [];

    const phoneArray = this.form.get("phone") as FormArray;
    phoneArray.clear();

    phones.forEach((phone) => {
      phoneArray.push(this.fb.control(phone, Validators.required));
    });

    this.form.patchValue({
      ...data,
      customerId: data?.customerId,
      fullName: data?.fullName,
      realEstateId: data?.id,
      phoneNumber: data?.numberToCall,
      type: this.convertStringToArray(data?.type),
      propertyStatusBefore: data?.status,
      alias: data?.alias,
      origin: data?.origin,
      brokerageFee: data?.brokerageFee,
      brokerageFeeCurrency: data?.brokerageFeeCurrency,
    });
  }

  private convertStringToArray(value: string) {
    return value?.split(",").filter((item: string) => item.trim() !== "");
  }
  // @ts-ignore
  private initForm(data?: RealEstateModel): FormGroup {
    const account = this.storageService.get(USER_INFO);
    return this.fb.group({
      customerId: [{ value: 0, nonNullable: true }, []],
      phone: this.fb.array([], [Validators.required]),
      fullName: [""],
      alias: [1],
      origin: [4, []],
      id: [null, []],
      address: ["", [Validators.required]],
      type: ["", [Validators.required]],
      purpose: ["", []],
      realEstateId: [null, [Validators.required]],
      title: ["Gọi điện cho", [handleTitle("fullName"), Validators.required]],
      content: ["", [Validators.required]],
      method: [1, [Validators.required]],
      statusCall: ["", [Validators.required]],
      propertyStatusAfter: ["", [Validators.required]],
      propertyStatusBefore: ["null", [Validators.required]],
      caller: [account?.phone, [Validators.required]],
      manager: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      record: ["https://google.com", [Validators.required]],
      siteHandoverTime: ["", [Validators.required]],
      brokerageFee: [],
      brokerageUnit: [], // auto tinhs toan neu brokerageFee thay doi.
      brokerageFeeCurrency: ["percent"],
      price: [],
      startTime: [
        ConvertDateTimeToString(new Date(), this.injector),
        [Validators.required],
      ],
      endTime: [
        ConvertDateTimeToString(new Date(), this.injector),
        [Validators.required],
      ], // will be updated later.
    });
  }
}
