import { Injector, NgModule } from "@angular/core";
import { FormSearchRealEstateHelper } from "src/app/public/helpers/form-search-real-estate.helper";
import { SyncUrlWithSearchRealEstateHelper } from "src/app/public/helpers/sync-url-with-search-real-estate.helper";
import { TransformToRealEstatePurposePipe } from "@pipe/transform-to-real-estate-purpose.pipe";

@NgModule({
  providers: [
    SyncUrlWithSearchRealEstateHelper,
    FormSearchRealEstateHelper,
    TransformToRealEstatePurposePipe,
  ],
})
export class HelpersModule {
  static injector: Injector;

  constructor() {}
}
