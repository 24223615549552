import { Pipe, PipeTransform } from "@angular/core";
import { USER_INFO } from "@constant/common.constant";
import { GroupModel } from "@model/group.model";
import { StorageService } from "@service/storage/storage.service";
import { maskPhoneNumber } from "@util/hidden-phone-number";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { APP_ACCOUNT_TYPE } from "src/app/public/constants/user.constant";
import { UserService } from "src/app/public/services/user/user.service";

@Pipe({
  name: "invisiblePhoneNumber",
  standalone: true,
})
export class InvisiblePhoneNumberPipe implements PipeTransform {
  constructor(
    private userService: UserService,
    private storageService: StorageService
  ) {}

  transform(value: any): Observable<any> {
    if (value === undefined || value === null) return of("--");

    return this.userService.user$.pipe(
      map((data: { type: string }) => {
        const groups = this.getUserGroups();
        const notHiddenPhone = groups.some(
          (group: GroupModel) => group.id === 2 || group.id === 4
        );

        if (data.type === APP_ACCOUNT_TYPE.SUPER_ADMIN || notHiddenPhone)
          return value;
        return maskPhoneNumber(value);
      })
    );
  }

  private getUserGroups(): GroupModel[] {
    const userGroups = this.storageService.get(USER_INFO).groups;
    return userGroups ? userGroups : [];
  }
}
