import { Component, inject, Input, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { HomeCallInsightComponent } from "@component/call-history-form-to-owner/containers/call-history-form/home-call-insight.component";
import { HistoryTableComponent } from "@component/call-history-form-to-owner/containers/call-history-list/history-table.component";
import { OwnerInfoComponent } from "@component/call-history-form-to-owner/containers/owner-info/owner-info.component";
import { RealEstateInfoComponent } from "@component/call-history-form-to-owner/containers/real-estate-info/real-estate-info.component";
import { CallRealEstateModel } from "@component/call-history-form-to-owner/call-history-container.component";
import { RealEstateHistoryFormHelper } from "@component/call-history-form-to-owner/call-history-sell-rent/form.helper";
import { FormCallHistoryInfoHelper } from "@component/call-history-form-to-owner/containers/call-history-form/call-history-form.helper";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "@page/real-estate-management/widgets/confirm-modal/confirm-modal.component";
import { debounceTime, filter, map, startWith } from "rxjs/operators";
import { BehaviorSubject, combineLatest } from "rxjs";
import { RealEstateService } from "@service/real-estate/real-estate.service";
import { CallToRealEstateOwnerPayload } from "@model/call-history-info.model";
import { HttpHistoryCallService } from "@service/call-history/http-history-call.service";
import { CallHistorySellRentService } from "@component/call-history-form-to-owner/call-history-sell-rent/call-history-sell-rent.service";
import { SwalWarningUtil } from "@util/swal-warning.util";
import { SwalSuccessUtil } from "@util/swal-success.util";
import { StorageService } from "@service/storage/storage.service";
import { HISTORY_CALL } from "@constant/common.constant";
import { CallCenterService } from "@service/socket/call-center.service";
import { RealEstateModel } from "@model/real-estate.model";

@Component({
  selector: "app-call-history-sell-rent",
  templateUrl: "./call-history-sell-rent.component.html",
  styleUrls: ["./call-history-sell-rent.component.scss"],
  standalone: true,
  imports: [
    OwnerInfoComponent,
    MatTooltipModule,
    RealEstateInfoComponent,
    HomeCallInsightComponent,
    HistoryTableComponent,
  ],
  providers: [FormCallHistoryInfoHelper, CallHistorySellRentService],
})
export class CallHistorySellRentComponent implements OnInit {
  private websocketService = inject(CallCenterService);
  private formHelper = inject(RealEstateHistoryFormHelper);
  private modalService = inject(NgbModal);
  private realEstateService = inject(RealEstateService);
  private historyCallService = inject(HttpHistoryCallService);
  private helperService = inject(CallHistorySellRentService);
  private storageService = inject(StorageService);

  // variables
  data$ = new BehaviorSubject(null);
  formData = this.formHelper.form;
  // props
  @Input() realEstate: any;
  @Input() addressIndex: number;
  @Input() set data(data: CallRealEstateModel) {
    this.data$.next(data);

    this.realEstate = data;
    this.formHelper.patchForm(data);
    this.websocketService.callToCustomer(data?.numberToCall);
  }

  ngOnInit() {
    // tinh toan lai tien moi gioi
    const fee$ = this.formData.controls["brokerageFee"];
    const currency$ = this.formData.controls["brokerageFeeCurrency"];
    combineLatest([
      fee$.valueChanges.pipe(startWith(fee$.value)),
      currency$.valueChanges.pipe(startWith(currency$.value)),
    ])
      .pipe(
        debounceTime(300),
        filter(([fee, currency]: any) => {
          return fee && currency;
        })
      )
      .subscribe(([fee, currency]: any) => {
        const price = this.formData.controls["price"].value;
        this.formData.patchValue({
          brokerageUnit: this.calculatePrice(currency, fee, price),
        });
      });

    this.formData.controls["id"].valueChanges.subscribe(
      (id) => (this.realEstate = { id: id })
    );
  }

  /**
   * @Description: Confirm hỏi trước khi tiến hành lưu.
   */
  onHandleSave($event?: boolean) {
    const stepHandlers = [
      () => this.handlerConfirmToSave($event),
      () => this.handlerFormValid(),
      () => this.handlerSaveRealEstate(),
      () => this.handlerSaveHistoryCall(),
      () => this.handlerSaveToStorage(),
      () => this.handlerResultProcess(),
      // save.
    ];
    // setup how it will be excuted. hàm đệ quy. recursive function.
    const executeStepHandlers = (index: number) => {
      if (index >= stepHandlers.length) return;
      stepHandlers[index]().then((result) => {
        if (result) {
          executeStepHandlers(index + 1);
        }
      });
    };
    // start breakpoint.
    executeStepHandlers(0);
  }

  // @function: Show confirm before save.
  private handlerConfirmToSave($event: any): Promise<boolean> {
    return new Promise((resolve) => {
      if ($event) {
        resolve(true);
      } else {
        // Popup confirm modal để hỏi trước khi tiến hành lưu.
        this.confirmBeforeSave()
          .closed.pipe()
          .subscribe((result) => resolve(result));
      }
    });
  }

  // @function: Validate form before save.
  private handlerFormValid(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.isValidFom()) {
        SwalWarningUtil.show(
          "Cảnh báo",
          "Bạn cần điền hết những trường bắt buộc!"
        );
        this.formData.markAllAsTouched();
      }
      resolve(this.isValidFom());
    });
  }

  // @function: Save real estate.
  private handlerSaveRealEstate(): Promise<boolean> {
    return new Promise((resolve) => {
      const data = this.formData.value;
      // call api get detail.
      const realEstate$ = this.realEstateService.getDetailRealEstate(data?.id);
      // patch with data change if contained.
      realEstate$
        .pipe(
          map((realEstate: RealEstateModel) => {
            realEstate.customers = realEstate?.customers.map(
              (customer: any) => {
                const phones = customer?.address.map(
                  (add: { phone: any }) => add.phone
                );
                if (phones.includes(data?.phoneNumber)) {
                  customer["alias"] = data?.alias;
                  customer["origin"] = data?.origin;
                }

                return customer;
              }
            );
            realEstate["brokerageFee"] = data?.brokerageFee;
            realEstate["brokerageFeeCurrency"] = data?.brokerageFeeCurrency;
            realEstate["siteHandoverTime"] = new Date(data?.siteHandoverTime);
            realEstate.type = data?.type.toString();
            realEstate.status = data?.propertyStatusAfter;

            return { ...realEstate };
          })
        )
        .subscribe((response) => {
          this.realEstateService
            .updateRealEstate(response)
            .subscribe((data) => {
              resolve(true);
            });
        });
    });
  }

  // @function: Save history call.
  private handlerSaveHistoryCall(): Promise<boolean> {
    return new Promise((resolve) => {
      // save.
      const formRawData = this.formData.getRawValue();
      // get payload
      this.helperService.saveCallHistory(formRawData).subscribe(({ data }) => {
        this.storageService.set(HISTORY_CALL, JSON.stringify(data));
        SwalSuccessUtil.show("Thành công!", "Nội dung ghi âm đang được xử lý!");
      });
      this.formData.markAsPristine();
      this.formData.markAsUntouched();

      resolve(true);
    });
  }

  private handlerSaveToStorage(): Promise<boolean> {
    return new Promise((resolve) => {});
  }

  private handlerResultProcess(): Promise<boolean> {
    return new Promise((resolve) => {});
  }

  private isValidFom(): boolean {
    return this.formData.valid;
  }

  /**
   * @Description: Confirm hỏi trước khi tiến hành lưu.
   */
  private confirmBeforeSave() {
    const modal = this.modalService.open(ConfirmModalComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
    });
    modal.componentInstance.title = "Lưu thông tin đã nhập?";
    modal.componentInstance.content =
      "Bạn cần lưu lại thông tin trước khi chuyển sang BĐS khác";

    return modal;
  }

  /**
   * @Description: Save thông tin người dùng của người dùng.
   * @private
   */
  private saveUserStep(): void {
    // save.
    const formRawData = this.formData.getRawValue();
    // get payload
    const payload: CallToRealEstateOwnerPayload =
      {} as CallToRealEstateOwnerPayload;

    this.historyCallService.saveCallToRealEstateOwner(payload);
    // storage into DB or local-storage.
  }

  /**
   * @Description: Save thông tin BĐS của
   * @private
   */

  private showWarning(): void {}

  private getValueFormData(): any {
    return this.formData.getRawValue();
  }

  private calculatePrice(currency: string, unit: number, price: number) {
    // calculate price based on FEE_TYPE
    switch (currency) {
      case "percent":
        return price * (Number(unit) / 100);
      case "monthly":
        return price * Number(unit);
      default:
        return unit;
    }
  }
}
