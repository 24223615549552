import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { OrderModel } from "@model/order.model";
import { OrderHttpService } from "src/app/public/services/order/order-http.service";

@Injectable({
  providedIn: "root",
})
export class CustomerOrderService {
  private customerOrderHttp = inject(OrderHttpService);

  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);

  get total$() {
    return this._total.asObservable();
  }

  get page$() {
    return this._page.asObservable();
  }

  get pageSize$() {
    return this._pageSize.asObservable();
  }

  get limit$() {
    return this._limit.asObservable();
  }

  public getListCustomerOrder(
    params: any,
    brandId: number
  ): Observable<OrderModel[]> {
    return this.customerOrderHttp.getCustomerOrders(brandId, params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _pageSize = data?.data?.length;
        const _limit = params?.limit;

        this._page.next(_page);
        this._total.next(_total);
        this._pageSize.next(_pageSize);
        this._limit.next(_limit);
      })
    );
  }

  public getDetailCustomerOrder(brandId: number, orderId: number) {
    return this.customerOrderHttp.getDetailCustomerOrder(brandId, orderId);
  }

  public createCustomerOrder(
    payload: OrderModel,
    brandId: string | number
  ) {
    return this.customerOrderHttp.createCustomerOrder(payload, brandId);
  }

  public updateCustomerOrder(
    payload: OrderModel,
    brandId: number,
    orderId: number
  ) {
    return this.customerOrderHttp.updateCustomerOrder(
      payload,
      brandId,
      orderId
    );
  }
  public deleteCustomerOrder(brandId: number, orderId: number) {
    return this.customerOrderHttp.deleteCustomerOrder(brandId, orderId);
  }

  public deleteOrderAddress(
    brandId: number,
    orderId: number,
    addressId: number
  ) {
    return this.customerOrderHttp.deleteOrderAddress(
      brandId,
      orderId,
      addressId
    );
  }
}
