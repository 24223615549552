<div class="p-1 border border-dashed h-100">
  <form action="" [formGroup]="formData">
    <legend>
      <div class="d-flex align-items-center justify-content-between">
        <span class="badge badge-soft-primary fs-14"
          ><i class="mdi mdi-circle-medium"></i>Bất động sản</span
        >
        <a
          ngbTooltip="Tạo mới 1 BĐS"
          [routerLink]="['/real-estate-management/detail', realEstate?.id]"
          target="_blank"
          class="btn-success btn btn-sm"
        >
          <i class="mdi mdi-plus"></i>
        </a>
      </div>
    </legend>
    <section>
      <div class="pb-2">
        <label class="form-label">Địa chỉ</label>
        <ng-select
          [items]="listRealEstate$ | async"
          bindLabel="fullAddress"
          bindValue="id"
          placeholder="Chọn địa chỉ"
          formControlName="id"
          [clearable]="false"
          [compareWith]="onHandleCompare"
        ></ng-select>
      </div>

      <div class="pb-2">
        <label class="form-label"></label>
        <div class="d-flex gap-3" style="height: 18px">
          <div class="form-check">
            <input
              [attr.disabled]="true"
              class="form-check-input"
              type="radio"
              name="purpose"
              formControlName="purpose"
              id="rent"
              [value]="'2'"
              style="opacity: 1"
            />
            <label class="form-check-label text" for="rent">Thuê</label>
          </div>
          <div class="form-check">
            <input
              [attr.disabled]="true"
              [value]="'1'"
              style="opacity: 1"
              formControlName="purpose"
              class="form-check-input"
              type="radio"
              name="purpose"
              id="buy"
            />
            <label class="form-check-label" for="buy">Bán</label>
          </div>
        </div>
      </div>

      <div class="pb-2 position-relative">
        <div ngbTooltip="Xem chi tiết BĐS" class="position-absolute r-0 z-99">
          <a
            target="_blank"
            [routerLink]="['/real-estate-management/detail', realEstate?.id]"
            class="btn btn-sm btn-warning"
          >
            <i class="mdi mdi-eye"></i>
          </a>
        </div>
        <swiper
          [loop]="true"
          [spaceBetween]="10"
          [navigation]="true"
          [thumbs]="{ swiper: thumbsSwiper }"
          (click)="onHandleOpenImage($event)"
          class="mySwiper2"
        >
          <ng-container *ngIf="realEstate?.photos?.length">
            <ng-container *ngFor="let media of realEstate?.photos">
              <ng-template swiperSlide>
                <img
                  *ngIf="CHECK_STYLE_URL(media.url) === 'image'"
                  [src]="media.url | getThumbnail"
                  onerror="
this.src='./assets/images/image-not-available.png'"
                  alt="image"
                />
                <video *ngIf="CHECK_STYLE_URL(media.url) === 'video'" controls>
                  <source [src]="media.url" type="video/mp4" />
                  <source [src]="media.url" type="video/ogg" />
                </video>
              </ng-template>
            </ng-container>
          </ng-container>

          <ng-template *ngIf="!realEstate?.photos?.length" swiperSlide
            ><img
              ngSrc="./assets/images/products/coming-soon.png"
              alt=""
              height="800"
              width="800"
          /></ng-template>
        </swiper>
      </div>

      <div class="pb-2">
        <label class="form-label"
          >Loại hình BĐS<span class="text-danger">*</span></label
        >

        <ng-select
          formControlName="type"
          [items]="realEstateTypes$ | async"
          [multiple]="true"
          bindValue="cKey"
          bindLabel="cValue"
          placeholder="Chọn loại hình BĐS..."
        ></ng-select>

        <small
          *ngIf="getControl('type').touched && getControl('type')?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </section>
  </form>
</div>
