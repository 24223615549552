export enum PHONE_CALL_STATUS {
  NOT_CONNECTED = 3,
  LISTENED = 2,
  NOT_CALL = 1,
}
export const STATUS_CALL = [
  {
    key: "Không gọi được",
    value: PHONE_CALL_STATUS.NOT_CALL,
  },
  {
    key: "Đã nghe máy",
    value: PHONE_CALL_STATUS.LISTENED,
  },
  {
    key: "Số không kết nối",
    value: PHONE_CALL_STATUS.NOT_CONNECTED,
  },
];
