import { inject, Injectable, Injector } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { USER_INFO } from "@constant/common.constant";
import { UserModel } from "@model/user.model";
import { StorageService } from "@service/storage/storage.service";
import { ConvertDateTimeToString } from "@util/date-time.util";
import { CallHistoryInfoModel } from "src/app/public/models/call-history-info.model";

@Injectable()
export class FormCallHistoryInfoHelper {
  private readonly formGroup: FormGroup;
  private fb = inject(FormBuilder);
  private storageService = inject(StorageService);
  private inject = inject(Injector);
  // variables
  private userInfo: UserModel = this.storageService.get(USER_INFO);

  constructor() {
    this.formGroup = this.initForm();
  }

  get form() {
    return this.formGroup as FormGroup;
  }

  set patchData(data: any) {
    const address = data?.customers[0].address[0];
    this.formGroup.patchValue({
      realEstateId: data.id,
      type: data.type,
      propertyStatusBefore: data?.status,
      phoneNumber: address?.phone,
      customerId: data?.customers[0]?.id,
      propertyManager: data?.createdBy,
      caller: this.userInfo?.username,
    });
  }

  // @ts-ignore
  private initForm(data?: CallHistoryInfoModel): FormGroup {
    return this.fb.group({
      id: [0, []],
      type: ["3", [Validators.required]],
      realEstateId: [null, [Validators.required]],
      customerId: ["", [Validators.required]],
      customerName: [""],
      title: ["Gọi điện cho", [handleTitle("fullName"), Validators.required]],
      content: ["", [Validators.required]],
      method: [1, [Validators.required]],
      statusCall: ["", [Validators.required]],
      propertyStatusAfter: ["", [Validators.required]],
      propertyStatusBefore: [{ value: "" }, [Validators.required]],
      caller: ["", [Validators.required]],
      propertyManager: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      record: ["https://google.com", [Validators.required]],
      siteHandoverTime: ["", [Validators.required]],
      brokerageFee: [""],
      brokerageUnit: [""], // auto tinhs toan neu brokerageFee thay doi.
      brokerageFeeCurrency: ["percent"],
      price: [],
      startTime: [
        ConvertDateTimeToString(new Date(), this.inject),
        [Validators.required],
      ],
      endTime: ["", [Validators.required]],
    });
  }
}

export function handleTitle(field: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.parent || !control.parent.get(field)) {
      return null;
    }

    const fieldValue = control.parent.get(field)?.value;
    if (fieldValue && control.value !== "Gọi điện cho " + fieldValue) {
      control.patchValue("Gọi điện cho " + fieldValue, { emitEvent: false });
    }

    return null;
  };
}
