import { Component, inject, Input } from "@angular/core";
import {
  AsyncPipe,
  DatePipe,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { StateContactHistoryService } from "@service/call-history/state-contact-history.service";
import { RealEstateModel } from "@model/real-estate.model";
import { COLUMNS_USER_HISTORY_INFO } from "@constant/table.constant";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { InvisiblePhoneNumberPipe } from "@pipe/invisible-phone-number.pipe";
import { GetFullStatusPipe } from "@pipe/get-full-status.pipe";

@Component({
  selector: "call-history-list-component",
  templateUrl: "./call-history-list.component.html",
  styleUrls: ["./call-history-list.component.scss"],
  imports: [
    NgForOf,
    NgTemplateOutlet,
    MatTableModule,
    AsyncPipe,
    DatePipe,
    NgIf,
    NgbTooltipModule,
    InvisiblePhoneNumberPipe,
    GetFullStatusPipe,
  ],
  providers: [StateContactHistoryService],
  standalone: true,
})
export class HistoryTableComponent {
  private contactHistoryService = inject(StateContactHistoryService);
  public contactHistories$ = this.contactHistoryService.contactHistories$;
  public isLoading$ = this.contactHistoryService.isLoading$;

  @Input() set realEstate(data: RealEstateModel) {
    if (data != null && data?.id) {
      this.contactHistoryService.getContactHistories({
        realEstateId: data?.id,
        page: 0,
        limit: 6,
      });
    }
  }

  protected readonly SUB_COLUMNS = COLUMNS_USER_HISTORY_INFO;
}
