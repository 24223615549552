<div class="p-2 border border-dashed h-100">
  <form [formGroup]="callHistoryForm">
    <legend class="d-flex justify-content-between align-items-center">
      <span class="badge badge-soft-primary fs-14"
        ><i class="mdi mdi-circle-medium"></i>Đơn hàng</span
      >

      <div>
        <button
          type="button"
          class="btn btn-success btn-sm"
          [ngbTooltip]="'Click để vào chi tiết đơn hàng'"
        >
          <a
            [routerLink]="['/crm/brands/', controls['brand']?.value, 'orders']"
            [queryParams]="{ id: controls['order'].value }"
            target="_blank"
            class="text-white"
          >
            <i class="ri-layout-grid-line align-middle"></i>
          </a>
        </button>

        <button
          type="button"
          class="btn btn-success btn-sm ms-2"
          [ngbTooltip]="'Click để tạo đơn hàng mới'"
        >
          <a
            [routerLink]="['/crm/brands', controls['brand']?.value, 'orders']"
            target="_blank"
            class="text-white"
          >
            <i class="ri-add-fill align-middle"></i>
          </a>
        </button>
      </div>
    </legend>

    <section class="row">
      <div class="pb-3 form-group" required>
        <label class="form-label">Thương hiệu</label>
        <ng-select
          [items]="brandList"
          placeholder="Chọn thương hiệu"
          formControlName="brand"
          bindLabel="name"
          bindValue="id"
        ></ng-select>
      </div>

      <div class="pb-3 form-group" required>
        <label class="form-label">Đơn hàng</label>
        <ng-select
          [items]="orders$ | async"
          placeholder="Chọn đơn hàng"
          formControlName="order"
          bindLabel="name"
          bindValue="id"
        ></ng-select>
      </div>
    </section>
  </form>
</div>
