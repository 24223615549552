import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { CALL_METHODS } from "src/app/public/constants/call-method.constant";
import { REAL_ESTATE_STATUS } from "src/app/public/constants/real-estate.constant";
import { STATUS_CALL } from "src/app/public/constants/status-call.constant";
import { CallHistoryOrderFormHelper } from "./call-history-order-form.helper";
import { HttpContactHistoryService } from "@service/call-history/http-contact-history.service";

@Component({
  selector: "app-call-history-order-form",
  templateUrl: "./call-history-order-form.component.html",
  standalone: true,
  providers: [CallHistoryOrderFormHelper, HttpContactHistoryService],
  imports: [FormsModule, ReactiveFormsModule, NgSelectModule, NgIf],
})
export class CallHistoryOrderFormComponent {
  readonly CALL_METHODS = CALL_METHODS;
  readonly STATUS_CALL = STATUS_CALL;
  readonly REAL_ESTATE_STATUS = REAL_ESTATE_STATUS;

  @Input() callHistoryForm: FormGroup;

  get controls() {
    return this.callHistoryForm.controls;
  }
}
