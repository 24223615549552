<div class="border border-dashed p-2 h-100">
  <form [formGroup]="callHistoryForm">
    <legend class="d-flex justify-content-between align-items-center">
      <span class="badge badge-soft-primary fs-14"
        ><i class="mdi mdi-circle-medium"></i>Khách hàng</span
      >
      <div>
        <button
          type="button"
          class="btn btn-success btn-sm"
          [ngbTooltip]="'Click để thêm mới khách hàng'"
        >
          <a
            class="text-white"
            [routerLink]="['/crm/brands', controls['brand'].value, 'orders']"
            [queryParams]="{ id: controls['order'].value }"
            target="_blank"
          >
            <i class="ri-add-fill align-middle"></i>
          </a>
        </button>
      </div>
    </legend>
    <section class="row">
      <div class="pb-3">
        <label class="form-label">Quý danh</label>
        <ng-select
          [items]="ALIAS"
          bindValue="key"
          [clearable]="false"
          bindLabel="label"
          placeholder="Chọn quý danh"
          formControlName="alias"
          [class.disabled]="true"
        >
        </ng-select>
      </div>
      <div class="pb-3 form-group" required>
        <label class="form-label">Họ và tên</label>
        <input
          formControlName="fullName"
          class="form-control"
          placeholder="Nhập họ & tên"
          [class.is-invalid]="
            controls['fullName'].touched && controls['fullName']?.errors
          "
          [class.disabled]="true"
        />
        <small
          *ngIf="controls['fullName'].touched && controls['fullName']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="pb-3 form-group" required>
        <label class="form-label">Số điện thoại</label>
        <div class="input-group">
          <input
            appOnlyPhoneNumber
            type="text"
            formControlName="phone"
            class="form-control"
            invisiblePhoneNumber
            placeholder="Nhập số điện thoại"
            [class.is-invalid]="
              controls['phone'].touched && controls['phone']?.errors
            "
            [class.disabled]="true"
          />
          <button
            class="btn btn-primary"
            type="button"
            ngbTooltip="Thêm mới SĐT"
          >
            <a
              class="d-inline-block text-white"
              [routerLink]="['/crm/brands', controls['brand'].value, 'orders']"
              [queryParams]="{ id: controls['order'].value }"
              target="_blank"
            >
              <i class="mdi mdi-plus"></i>
            </a>
          </button>
        </div>

        <small
          *ngIf="controls['phone'].touched && controls['phone']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
      <div class="pb-3 form-group">
        <label class="form-label">Nguồn</label>
        <ng-select
          [items]="ORIGIN_PROVIDE"
          bindValue="key"
          [clearable]="false"
          formControlName="origin"
          bindLabel="label"
          placeholder="Chọn nguồn"
          [class.disabled]="true"
        >
        </ng-select>
      </div>
    </section>
  </form>
</div>
