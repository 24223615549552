export const CALL_METHODS = [
  {
    key: "Gọi điện thoại",
    cValue: 1,
  },
  {
    key: "Gửi SMS",
    cValue: 2,
  },
  {
    key: "Gửi email",
    cValue: 3,
  },
  {
    key: "Zalo",
    cValue: 4,
  },
  {
    key: "Khác",
    cValue: 5
  },
];
