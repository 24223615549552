<div class="p-1 border border-dashed h-100">
  <form action="" [formGroup]="formData" (ngSubmit)="submitForm()">
    <legend>
      <div class="d-flex align-items-center justify-content-between">
        <span class="badge badge-soft-primary fs-14"
          ><i class="mdi mdi-circle-medium"></i>Thông tin cuộc gọi</span
        >
      </div>
    </legend>
    <section class="row">
      <div class="col-md-6 pb-2">
        <label class="form-label"
          >Trạng thái BĐS trước khi gọi<span class="text-danger">*</span></label
        >
        <ng-select
          [readonly]="true"
          [items]="REAL_ESTATE_STATUS"
          bindValue="cKey"
          [clearable]="false"
          formControlName="propertyStatusBefore"
          bindLabel="cValue"
          placeholder="Phương thức"
        >
        </ng-select>
      </div>

      <div class="col-md-6 pb-2">
        <label class="form-label"
          >Tiêu đề<span class="text-danger">*</span></label
        >
        <input formControlName="title" class="form-control" />
        <small
          *ngIf="controls['title'].touched && controls['title']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-6 pb-2">
        <label class="form-label"
          >Trạng thái BĐS sau khi gọi<span class="text-danger">*</span></label
        >
        <ng-select
          [items]="REAL_ESTATE_STATUS"
          bindValue="cKey"
          [clearable]="false"
          formControlName="propertyStatusAfter"
          bindLabel="cValue"
          placeholder="Phương thức"
        >
        </ng-select>
        <small
          *ngIf="
            controls['propertyStatusAfter'].touched &&
            controls['propertyStatusAfter']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-6 pb-2">
        <label class="form-label"
          >Phương thức <span class="text-danger">*</span></label
        >
        <ng-select
          [items]="CALL_METHODS"
          bindValue="cValue"
          [clearable]="false"
          formControlName="method"
          bindLabel="key"
          placeholder="Phương thức"
        >
        </ng-select>
        <small
          *ngIf="controls['method'].touched && controls['method']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-6 pb-2">
        <label class="form-label">Phí môi giới</label>
        <div class="input-group">
          <input
            type="number"
            placeholder="Nhập phí môi giới"
            class="form-control col-7"
            formControlName="brokerageFee"
          />
          <ng-select
            [items]="FEE_TYPE"
            placeholder="Loại phí"
            bindLabel="label"
            bindValue="key"
            formControlName="brokerageFeeCurrency"
            class="col-5"
          ></ng-select>
        </div>
      </div>

      <div class="col-md-6 pb-2">
        <label class="form-label"
          >Kết quả cuộc gọi<span class="text-danger">*</span></label
        >
        <ng-select
          [items]="STATUS_CALL"
          bindValue="value"
          [clearable]="false"
          formControlName="statusCall"
          bindLabel="key"
          placeholder="Phương thức"
        >
        </ng-select>
        <small
          *ngIf="
            controls['statusCall'].touched && controls['statusCall']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-6 pb-2">
        <label class="form-label"
          >Thành tiền môi giới<span class="text-danger"></span
        ></label>
        <div
          class="search-box form-control readonly"
          [style.pointer-events]="'none'"
        >
          {{
            formData.controls["brokerageUnit"].value | transformPriceToString
          }}
        </div>
      </div>

      <div class="col mb-3">
        <label class="form-label">Thời gian bàn giao mặt bằng<span class="text-danger">*</span></label>
        <div class="search-box">
          <input
            class="flatpickr-input form-control"
            type="text"
            placeholder="Chọn thời gian bàn giao mặt bằng"
            mwlFlatpickr
            altFormat="d/m/Y"
            [locale]="language"
            [altInput]="true"
            [convertModelValue]="false"
            formControlName="siteHandoverTime"
          />
          <i class="ri-calendar-2-line search-icon"></i>
          <small
            *ngIf="
              getControl('siteHandoverTime').touched &&
              getControl('siteHandoverTime')?.errors
            "
            class="text-danger"
            >Trường thông tin bắt buộc</small
          >
        </div>
      </div>

      <div class="col-md-12 pb-2">
        <label class="form-label"
          >Nội dung<span class="text-danger">*</span></label
        >
        <textarea
          cols="30"
          rows="6"
          type="name"
          formControlName="content"
          class="form-control"
        ></textarea>
        <small
          *ngIf="controls['content'].touched && controls['content']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </section>
  </form>
</div>
