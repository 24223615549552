import { Injectable } from "@angular/core";
import { API_URL } from "@constant/api-url.constant";
import { BrandModel } from "@model/brand.model";
import { BaseHttpRequest } from "@service/http/base-http-request.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BrandHttpService extends BaseHttpRequest {
  public getListBrand(params: any): Observable<BrandModel[]> {
    const { page } = params;
    return this.get(API_URL.LIST_BRAND, {
      params: { ...params, page: Number(page - 1) },
    });
  }

  public getDetailBrand(id: string | number): Observable<BrandModel> {
    return this.get<BrandModel>(`${API_URL.BRAND_EDITOR}/${id}`);
  }

  public createBrand(payload: BrandModel) {
    return this.post(API_URL.BRAND_EDITOR, payload);
  }

  public updateBrand(id: string | number, payload: BrandModel) {
    return this.patch(`${API_URL.BRAND_EDITOR}/${id}`, payload);
  }

  public deleteBrand(id: number) {
    return this.delete(`${API_URL.BRAND_EDITOR}/${id}`);
  }

  public activeBrand(id: number) {
    return this.patch(
      `${API_URL.BRAND_EDITOR}/${id}/${API_URL.ACTIVE}`,
      {}
    );
  }

  public inactiveBrand(id: number) {
    return this.patch(
      `${API_URL.BRAND_EDITOR}/${id}/${API_URL.INACTIVE}`,
      {}
    );
  }
}
