import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { ALIAS, ORIGIN_PROVIDE } from "@constant/common.constant";
import { InvisiblePhoneNumberDirective } from "@directive/invisible-phone-number.directive";
import { OnlyPhoneNumberDirective } from "@directive/only-phone-number.directive";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";

@Component({
  selector: "app-order-owner",
  templateUrl: "./order-owner.component.html",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    UiSwitchModule,
    NgbTooltipModule,
    RouterLink,
    OnlyPhoneNumberDirective,
    InvisiblePhoneNumberDirective,
    NgIf,
  ],
})
export class OrderOwnerComponent {
  readonly ALIAS = ALIAS;
  readonly ORIGIN_PROVIDE = ORIGIN_PROVIDE;

  @Input() callHistoryForm: FormGroup;

  get controls() {
    return this.callHistoryForm.controls;
  }
}
