<div class="p-2 border border-dashed">
  <form [formGroup]="callHistoryForm">
    <legend>
      <span class="badge badge-soft-primary fs-14"
        ><i class="mdi mdi-circle-medium"></i>Thông tin cuộc gọi</span
      >
    </legend>
    <section class="row">
      <div class="col-md-6 pb-3 form-group" required>
        <label class="form-label">Tiêu đề</label>
        <input
          formControlName="title"
          class="form-control"
          placeholder="Nhập tiêu đề"
          [class.is-invalid]="
            controls['title'].touched && controls['title']?.errors
          "
        />
        <small
          *ngIf="controls['title'].touched && controls['title']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
      <div class="col-md-6 pb-3 form-group" required>
        <label class="form-label">Phương thức </label>
        <ng-select
          [items]="CALL_METHODS"
          bindValue="cValue"
          [clearable]="false"
          formControlName="method"
          bindLabel="key"
          placeholder="Chọn phương thức"
          [class.is-invalid]="
            controls['method'].touched && controls['method']?.errors
          "
        >
        </ng-select>
        <small
          *ngIf="controls['method'].touched && controls['method']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
      <div class="col-md-6 pb-3 form-group" required>
        <label class="form-label">Kết quả cuộc gọi</label>
        <ng-select
          [items]="STATUS_CALL"
          bindValue="value"
          [clearable]="false"
          formControlName="statusCall"
          bindLabel="key"
          placeholder="Chọn kết quả cuộc gọi"
        >
        </ng-select>
        <small
          *ngIf="
            controls['statusCall'].touched && controls['statusCall']?.errors
          "
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>

      <div class="col-md-12 pb-3 form-group" required>
        <label class="form-label">Nội dung</label>
        <textarea
          cols="30"
          rows="8"
          type="name"
          formControlName="content"
          class="form-control"
          placeholder="Nhập nội dung cuộc gọi"
          [class.is-invalid]="
            controls['content'].touched && controls['content']?.errors
          "
        ></textarea>
        <small
          *ngIf="controls['content'].touched && controls['content']?.errors"
          class="text-danger"
          >Trường thông tin bắt buộc</small
        >
      </div>
    </section>
  </form>
</div>
