import { AuthenticationService } from "src/app/public/services/auth/auth.service";
import { UserService } from "./../../public/services/user/user.service";
import { UserModel } from "./../../public/models/user.model";
import { BehaviorSubject } from "rxjs";
import { Component } from "@angular/core";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { AsyncPipe, NgIf } from "@angular/common";

@Component({
  selector: "app-user-info",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  providers: [UserService],
  imports: [NgbDropdownModule, NgIf, AsyncPipe],
  standalone: true,
})
export class UserComponent {
  public userInfo$: BehaviorSubject<UserModel>;
  constructor(
    private userService: UserService,
    private authService: AuthenticationService
  ) {
    this.userInfo$ = this.userService.user$ as BehaviorSubject<UserModel>;
  }

  logout(): void {
    this.authService.logout();
    location.reload();
  }
}
