import { API_URL } from "@constant/api-url.constant";
import { UserModel } from "@model/user.model";
import { Observable } from "rxjs";
import { BaseHttpRequest } from "../http/base-http-request.service";
import { Injectable } from "@angular/core";
import { RealEstateModel } from "@model/real-estate.model";
import { DivisionModel } from "@model/division.model";
import { GroupModel } from "@model/group.model";
import { PositionModel } from "@model/position.model";
import { EmployeeModel } from "@model/employee.model";

@Injectable({
  providedIn: "root",
})
export class UserHttpService extends BaseHttpRequest {
  /**
   * @Function get current user login
   * @returns Current user login
   */
  public getUserLogin(): Observable<UserModel> {
    return this.get(API_URL.GET_USER_LOGIN) as Observable<UserModel>;
  }

  // INACTIVE USER
  public inactiveUser(id: string): Observable<any> {
    return this.post<any>(`${API_URL.INACTIVE_USER}/${id}`, {});
  }

  // INACTIVE USER
  public activeUser(id: string): Observable<any> {
    return this.post<any>(`${API_URL.ACTIVE_USER}/${id}`, {});
  }

  //GET LIST AGENT
  public getListAgent(params: any): Observable<UserModel> {
    const { page } = params;
    return this.get(API_URL.LIST_USER_AGENT, {
      params: { ...params, page: Number(page - 1) },
    });
  }

  public getRealEstatesUploaded(
    id: string | number,
    params: any
  ): Observable<RealEstateModel[]> {
    const { page } = params;
    return this.get<any>(
      `${API_URL.LIST_USER_AGENT}/${id}/${API_URL.REAL_ESTATE_UPLOADED}`,
      {
        params: { ...params, page: Number(page - 1) },
      }
    );
  }

  // GET LIST NAME OF USERS BY PHONE NUMBER
  public getListUserName(phoneNumbers: {
    phoneNumbers: string[];
  }): Observable<any> {
    return this.post(API_URL.FETCH_USERS, phoneNumbers);
  }

  // DIVISION
  public getListDivision(): Observable<DivisionModel[]> {
    return this.get(API_URL.LIST_DIVISION);
  }

  public createDivision(payload: DivisionModel): Observable<DivisionModel> {
    return this.post(API_URL.DIVISION, payload);
  }

  public getDetailDivision(id: number): Observable<DivisionModel> {
    return this.get(`${API_URL.DIVISION}/${id}`);
  }

  public updateDivision(id: number, payload: DivisionModel) {
    return this.patch(`${API_URL.DIVISION}/${id}`, payload);
  }

  public deleteDivision(id: number) {
    return this.delete(`${API_URL.DIVISION}/${id}`);
  }

  //GROUP
  public getListGroup(): Observable<GroupModel[]> {
    return this.get(API_URL.LIST_GROUP);
  }

  public createGroup(payload: GroupModel): Observable<GroupModel> {
    return this.post(API_URL.GROUP, payload);
  }

  public getDetailGroup(id: number): Observable<GroupModel> {
    return this.get(`${API_URL.GROUP}/${id}`);
  }

  public updateGroup(id: number, payload: GroupModel) {
    return this.patch(`${API_URL.GROUP}/${id}`, payload);
  }

  public deleteGroup(id: number) {
    return this.delete(`${API_URL.GROUP}/${id}`);
  }

  //POSITION
  public getListPosition(): Observable<PositionModel[]> {
    return this.get(API_URL.LIST_POSITION);
  }

  //EMPLOYEE
  public getListEmployee(params: any): Observable<EmployeeModel[]> {
    const { page } = params;
    return this.get(API_URL.LIST_EMPLOYEE, {
      params: { ...params, page: Number(page - 1) },
    });
  }

  public createEmployee(payload: EmployeeModel): Observable<EmployeeModel> {
    return this.post(API_URL.EMPLOYEE, payload);
  }

  public getDetailEmployee(id: number): Observable<EmployeeModel> {
    return this.get(`${API_URL.EMPLOYEE}/${id}`);
  }

  public updateEmployee(id: number, payload: EmployeeModel): Observable<any> {
    return this.patch(`${API_URL.EMPLOYEE}/${id}`, payload);
  }

  public deleteEmployee(id: number): Observable<any> {
    return this.delete(`${API_URL.EMPLOYEE}/${id}`);
  }

  public activeEmployee(ids: number[]) {
    return this.post(`${API_URL.EMPLOYEE}/${API_URL.ACTIVE}`, ids);
  }

  public deactivateEmployee(ids: number[]) {
    return this.post(`${API_URL.EMPLOYEE}/${API_URL.DEACTIVE}`, ids);
  }
}
