import Swal from "sweetalert2";

export class SwalSuccessUtil {
  private static title: string = "Thành công!";
  private static iconHtml: string =
    "<lord-icon\n" +
    '                src="https://cdn.lordicon.com/hzomhqxz.json"\n' +
    '                trigger="loop"\n' +
    '                colors=" primary:#405189,secondary:#08a88a"\n' +
    '                style="width: 150px; height: 150px; border: none"\n' +
    "              >\n" +
    "              </lord-icon>";
  static show(
    title: string = this.title,
    htmlContent: string = "",
    content: string = "",
    iconHtml: string = this.iconHtml
  ) {
    const html = content ? content : '<div class="">' + htmlContent + "</div>";
    return Swal.fire({
      title: '<h3 class="text-success text-info-bold">' + title + "</h3>",
      html: html,
      iconHtml: iconHtml,
      customClass: {
        icon: "no-border", // Remove the default border if needed,
        confirmButton: "custom-swal2-confirm",
      },
    });
  }
}
