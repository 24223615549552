<div class="px-2">
  <div class="align-right mb-1">
    <button (click)="onHandleSave()" class="btn btn-success">
      <i class="mdi mdi-content-save"></i>
      Lưu
    </button>
  </div>
  <div class="d-flex">
    <div class="col-3 align-self-stretch">
      <owner-info-component
        [formData]="formData"
        [addressIndex]="addressIndex"
      ></owner-info-component>
    </div>
    <div class="col-3 align-self-stretch">
      <real-estate-info-component
        [formData]="formData"
        (onEmitSubmit)="onHandleSave(true)"
      ></real-estate-info-component>
    </div>
    <div class="col-6 align-self-stretch">
      <call-history-form-component
        [formData]="formData"
      ></call-history-form-component>
    </div>
  </div>
  <div class="row align-self-stretch">
    <call-history-list-component
      [realEstate]="realEstate"
    ></call-history-list-component>
  </div>
</div>
