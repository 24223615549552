import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import {
  AsyncPipe,
  CommonModule,
  NgForOf,
  NgIf,
  NgOptimizedImage,
} from "@angular/common";
import { SwiperModule } from "swiper/angular";
import { CheckStyleUrl } from "src/app/public/utils/check-file-type";
import { GetThumbnailImagePipe } from "src/app/public/pipes/get-thumbnail-image.pipe";
import { RealEstateModel } from "src/app/public/models/real-estate.model";
import { CategoryService } from "src/app/public/services/categories/category.service";
import { MatSelectModule } from "@angular/material/select";
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BehaviorSubject } from "rxjs";
import { RealEsateHttpService } from "@service/real-estate/real-esate-http.service";
import { SliderImageDetailOriginalComponent } from "@page/real-estate-management/widgets/slider-image-detail-original/slider-image-detail-original.component";
import { RouterLink } from "@angular/router";
import { ConfirmModalComponent } from "@page/real-estate-management/widgets/confirm-modal/confirm-modal.component";

@Component({
  selector: "real-estate-info-component",
  templateUrl: "./real-estate-info.component.html",
  styleUrls: ["./real-estate-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgSelectModule,
    NgIf,
    SwiperModule,
    GetThumbnailImagePipe,
    AsyncPipe,
    NgForOf,
    MatSelectModule,
    CommonModule,
    MatTooltipModule,
    NgbTooltipModule,
    RouterLink,
    NgOptimizedImage,
  ],
})
export class RealEstateInfoComponent implements OnInit {
  readonly LIMIT_DEFAULT = 48;
  readonly PAGE_DEFAULT = 1;
  readonly CHECK_STYLE_URL = CheckStyleUrl;

  // Inject Services
  private modalService = inject(NgbModal);
  private categoryService = inject(CategoryService);
  private realEstateHttpService = inject(RealEsateHttpService);

  // Input & Output
  @Input() formData: UntypedFormGroup;
  @Output() onEmitSubmit = new EventEmitter<any>();

  // Variables
  public realEstate: RealEstateModel;
  public thumbsSwiper: any;
  public listRealEstate$ = new BehaviorSubject(null);
  public realEstateTypes$ = this.categoryService.getAllType();

  ngOnInit(): void {
    this.observerPhoneChange();
    this.observerAddressChange();
  }

  onHandleCompare(item: any, selected: any) {
    return item?.id?.toString() === selected?.toString();
  }

  onHandleOpenImage(event: any): void {
    const swiper = event[0];

    if (swiper) {
      const content = this.modalService.open(
        SliderImageDetailOriginalComponent,
        {
          centered: true,
          fullscreen: true,
          backdrop: true,
        }
      );
      content.componentInstance.photos = this.realEstate?.photos;
      content.componentInstance.currentSlide = Number(swiper?.activeIndex - 1);
    }
  }

  getControl(control: string) {
    return this.formData.controls[control] as UntypedFormControl;
  }

  private observerAddressChange(): void {
    this.formData
      .get("id")
      .valueChanges.pipe(
        startWith([this.formData.get("id").value]),
        pairwise(),
        distinctUntilChanged(),
        filter(([before, current]) => {
          if (before && current) return before !== current;
          return null;
        })
      )
      .subscribe(([before, currentId]) => {
        const formDirty = this.checkFormDirty(["id"]);
        if (formDirty) {
          this.confirmBeforeSave()
            .closed.pipe()
            .subscribe((confirm) => {
              this.handleConfirmResponse(
                confirm,
                this.formData.get("id"),
                before,
                currentId
              );
            });
        } else {
          this.fetchRealEstateById(currentId);
        }
      });
  }

  private observerPhoneChange(): void {
    this.formData
      .get("phone")
      .valueChanges.pipe(
        startWith([this.formData.get("phone").value]),
        map((phone) => phone.filter(Boolean)),
        filter((phone) => phone.length > 0),
        switchMap((phone) => this.fetchRealEstate({ ownerPhone: phone })),
        tap(
          (data) =>
            (this.realEstate = data.find(
              ({ id }: any) => id === this.formData.get("id").value
            ))
        )
      )
      .subscribe((data) => this.listRealEstate$.next(data));
  }

  private checkFormDirty(avoidKeys: string[]) {
    const listDirty = Object.keys(this.formData.controls)
      .filter((k) => !avoidKeys.includes(k))
      .filter((k) => this.formData.get(k).dirty);

    return listDirty.length > 0;
  }

  // Call API Lấy danh sách BĐS bởi sđt của chủ nhà.
  private fetchRealEstate(params: any) {
    return this.realEstateHttpService
      .getRealEstatesV2({
        ...params,
        page: this.PAGE_DEFAULT,
        limit: this.LIMIT_DEFAULT,
      })
      .pipe(
        map((res: any) =>
          res?.data.map((d: any) => ({
            fullAddress: d?.address.fullAddress,
            ...d,
          }))
        )
      );
  }

  private fetchRealEstateById(id: any) {
    return this.realEstateHttpService
      .getRealEstate(id)
      .subscribe((response): any => {
        const { item } = response;
        const type = this.convertStringToArray(item?.type);
        this.formData.patchValue({
          brokerageUnit: null,
          realEstateId: item?.id,
          status: item?.status,
          type: type,
          purpose: item?.purpose,
          content: null,
          method: 1,
          statusCall: null,
          propertyStatusAfter: null,
          propertyStatusBefore: item?.status,
          siteHandoverTime: null,
          brokerageFee: item?.brokerageFee,
          brokerageFeeCurrency: ["percent"],
          price: item?.price,
        });
        this.realEstate = item;
      });
  }

  private confirmBeforeSave() {
    const modal = this.modalService.open(ConfirmModalComponent, {
      size: "md",
      centered: true,
      backdrop: "static",
    });
    modal.componentInstance.title = "Lưu thông tin đã nhập?";
    modal.componentInstance.content =
      "Bạn có muốn lưu lại trước khi chuyển sang BĐS khác? Các dữ liệu đã điền sẽ bị loại bỏ.";
    modal.componentInstance.btnConfirmTitle = "Lưu và tiếp tục";
    modal.componentInstance.btnRejectTitle = "Bỏ qua lưu và tiếp tục";
    modal.componentInstance.hasClose = true;

    return modal;
  }

  private convertStringToArray(value: string) {
    return value?.split(",").filter((item: string) => item.trim() !== "");
  }

  private resetControlValue(control: FormControl, value: any) {
    control.setValue(null, { onlySelf: true });
    control.setValue(value, { onlySelf: true });
  }

  // Helper methods for better clarity and separation of logic.
  private handleConfirmResponse(
    confirm: boolean | undefined,
    control: any,
    before: any,
    currentId: number
  ): void {
    if (confirm === undefined) {
      this.resetControlValue(control, before);
      return;
    }

    if (!confirm) {
      this.skipSaveAndFetch(currentId);
      return;
    }

    this.processSubmit(control, before, currentId);
  }

  private skipSaveAndFetch(currentId: number): void {
    // Logic for skipping save and fetching real estate by ID.
    this.fetchRealEstateById(currentId);
  }

  private processSubmit(control: any, before: any, currentId: number): void {
    this.onEmitSubmit.emit();

    if (this.formData.invalid) {
      this.resetControlValue(control, before);
    } else {
      this.fetchRealEstateById(currentId);
    }
  }
}
