export const API_URL = {
  // AUTH
  LOGIN: "auth/login",
  REFRESH_TOKEN: "auth/refresh-token",
  GET_USER_LOGIN: "auth/login-user",
  CHANGE_PWD: "auth/register/change-pw",

  // Real Estate
  REAL_ESTATE: "land-management/land",
  LIST_REAL_ESTATE: "land-management/search",
  ADMIN_REAL_ESTATE_SEARCH: "land-management/admin-portal/search",
  SYNC_REAL_ESTATE: "land-management/land/sync-to-elastic", // remove
  LIST_REAL_ESTATE_POSTGRES: "land-management/search/postgres-db",
  DETAIL_REAL_ESTATE: "land-management/land",
  APPROVED_REAL_ESTATE: "land-management/land/approve",
  REJECT_REAL_ESTATE: "land-management/land/reject",
  IN_POLYGON: "land-management/search/in-polygon",
  LAND_MANAGEMENT: "land-management",
  AUDITS: "audits",

  // Real Estate Raw
  DELETE_REAL_ESTATE_RAW: "land-management/land/raw-info",
  REAL_ESTATE_QUICK_UP: "land-management/land/raw-info",
  LIST_REAL_ESTATE_QUICK_UP: "land-management/land/raw-info/search",
  DETAIL_REAL_ESTATE_RAW: "land-management/land/raw-info",

  //  Category
  ALL_CATEGORY: "auth/category/getAll",
  CREDENTIAL: "auth/category/getAWSCredential",

  //Get name of users
  FETCH_USERS: "user-management/user/fetch-users",

  // Brand
  LIST_BRAND: "crm/admin-portal/brands/search",
  BRAND_EDITOR: "crm/admin-portal/brands",
  BRANDS: "brands",
  ORDERS: "orders",
  INACTIVE: "inactive",

  // User-Customer
  USER_CUSTOMERS: "user-management/customers",
  DETAIL_USER_CUSTOMER: "user-management/user",
  HISTORY_VIEW_USER_CUSTOMER: "land-management/land/view-history/user",
  CUSTOMER_EDITOR: "user-management/admin-portal/customers",

  APPROVED_USER_CUSTOMER: "user-management/user/approve",
  REJECT_USER_CUSTOMER: "user-management/user/reject",
  INACTIVE_USER: "user-management/user/inactive",
  ACTIVE_USER: "user-management/user/active",

  //User-Agent
  LIST_USER_AGENT: "user-management/agents",
  REAL_ESTATE_UPLOADED: "real-estates-uploaded",

  //User-Employee
  LIST_EMPLOYEE: "user-management/manage/staff/search",
  EMPLOYEE: "user-management/manage/staff",
  DEACTIVE: "deactivate",

  //Email
  SEND_EMAIL: "message/emails/real-estates",
  EMAIL_CUSTOMER: "crm/real-estate/email/send",

  //Notification
  SEND_NOTIFICATION: "message/notifications/send-notify",

  //Permission-Action
  LIST_ACTION: "auth/manage/actions/search",
  ACTION: "auth/manage/actions",

  //Permission-Service
  LIST_SERVICE: "auth/manage/services/search",
  SERVICE: "auth/manage/services",

  //Permission-Resource
  LIST_RESOURCE: "auth/manage/resource-services/search",
  RESOURCE: "auth/manage/resource-services",

  //Resource-Action
  LIST_RESOURCE_ACTION: "auth/manage/resource-actions/search",
  RESOURCE_ACTION: "auth/manage/resource-actions",

  //Permission-Policy
  LIST_POLICY: "auth/manage/policies/search",
  POLICY: "auth/manage/policies",

  //Permission-Role
  LIST_ROLE: "auth/manage/roles/search",
  ROLE: "auth/manage/roles",

  //Organizational-Division
  LIST_DIVISION: "user-management/manage/division/all",
  DIVISION: "user-management/manage/division",

  //Organizational-Group
  LIST_GROUP: "user-management/manage/group/all",
  GROUP: "user-management/manage/group",

  //Organizational-Position
  LIST_POSITION: "user-management/manage/position/all",

  //Save Search Real Estate of Customer
  SUBSCRIBE: "real-estate/subscribe",
  RECEIVE_EMAIL: "receive-email",
  ACTIVE: "active",
  CRM: "crm",
  HISTORY_SEARCH: "real-estate/history-search",
  ADMIN_PORTAL: "admin-portal",
  SEARCH_REAL_ESTATE: "search-real-estate",

  //Schedule-Email
  SCHEDULE_EMAIL: "crm/config/schedule-email",

  // CALL HISTORIES
  CONTACT_HISTORY: "crm/owner/contact-history",
  OWNER_CONTACT_HISTORY: "crm/log-calls/real-estate",

  // CUSTOMER ORDER
  CUSTOMERS: "customers",
  PROPERTY_CRITERIA: "property-criteria",
  SEARCH_REAL_ESTATE_CRITERIA: "crm/admin-portal/customers/",
  ADDRESS: "address",

  // BUSINESS DOMAIN
  RESOURCES: "resources",
  BUSINESS_DOMAIN: "business-domain",

  // CALL HISTORY ORDER
  ORDER: "order",
  LOG_CALLS: "log-calls",
  LOG_CALLS_REAL_ESTATE: "log-calls/real-estate",
  RECORDS: "records",
};

export const SOCKET_URL = {
  CRM: {
    CONNECT: "crm/ws", // URL để gọi connect đến Socket.
    GET_SESSION: "/app/session", // request session cho kết nối.
    OBSERVER_SESSION: "/user/queue/session", // lắng nghe phản hổi session từ server.
    GET_CALL: "/app/call",
    OBSERVER_CALL: "/user/queue/call-observer",
  },
};
