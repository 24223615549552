import { inject, Injectable } from "@angular/core";
import { CallHistoryContainerComponent } from "@component/call-history-form-to-owner/call-history-container.component";
import { WaitingLoadingModalComponent } from "@component/waiting-loading-modal/waiting-loading-modal.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Client, Message } from "@stomp/stompjs";
import { IPublishParams } from "@stomp/stompjs/src/types";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, filter, take } from "rxjs/operators";
import * as SockJS from "sockjs-client";
import { environment } from "../../../../environments/environment";
import { SOCKET_URL } from "../../constants/api-url.constant";
import {
  ACTION_TYPE,
  CallToCustomerModel,
  STATUS_CALL,
} from "../../models/call-to-customer.model";
import { StorageService } from "../storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class CallCenterService {
  private storageService = inject(StorageService);
  private modalService = inject(NgbModal);

  private _status$ = new BehaviorSubject<STATUS_CALL>(STATUS_CALL.WAITING);
  private sessionId: string = null;
  private client: Client;
  private ext: string = this.storageService.get("EXT");

  private messages: BehaviorSubject<string> = new BehaviorSubject<string>("");

  private _isConnected$ = new BehaviorSubject<boolean>(null);

  constructor() {
    // this.connect();
  }

  // @ts-ignore
  get status(): Observable<STATUS_CALL> {
    return this._status$.asObservable();
  }

  set status(value: STATUS_CALL) {
    this._status$.next(value);
  }

  get isConnected$(): Observable<boolean> {
    return this._isConnected$.asObservable();
  }

  connect() {
    if (!this.ext) return;

    const params = new URLSearchParams({
      ext: this.ext,
    });

    const url: string = `${environment.apiURL}/${
      SOCKET_URL.CRM.CONNECT
    }?${params.toString()}`;
    this.client = new Client({
      // @ts-ignore
      webSocketFactory: () => new SockJS(url),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    this.client.onConnect = (frame) => {
      this.sessionId = frame?.headers["user-name"];

      if (this.sessionId) {
        this.observerSession();
        this.getSession();
      }
    };

    this.client.onDisconnect = () => {
      console.log("Disconnected from WebSocket");
    };

    this.client.activate(); // Kích hoạt kết nối
  }

  public callToCustomer(phone: string) {
    const body: CallToCustomerModel = {
      type: ACTION_TYPE.REQUEST,
      action: "PHONE_CALL",
      status: "WAITING",
      requestUser: this.sessionId,
      from: "",
      to: phone,
      ext: this.ext,
    };
    const params: IPublishParams = {
      destination: `${SOCKET_URL.CRM.GET_CALL}/${this.sessionId}`,
      body: JSON.stringify(body),
    };
    this.client.publish(params);
  }

  private observerSession() {
    if (this.client.connected) {
      this.client.subscribe(
        `${SOCKET_URL.CRM.OBSERVER_SESSION}`,
        (message: Message) => {
          this.messages.next(message.body);
          this.observerCallResult(message.body);
        }
      );
    } else {
      setTimeout(() => {
        this.observerSession();
      }, 5000);
      console.warn(
        "Không thể gửi yêu cầu observer. WebSocket chưa được kết nối."
      );
    }
  }

  private observerCallResult(requestUser: string) {
    this.client.subscribe(
      `${SOCKET_URL.CRM.OBSERVER_CALL}/${requestUser}`,
      (message: Message) => {
        console.log("Phone Call" + message.body);
        this.messages.next(message.body);
      }
    );
  }

  private getSession() {
    if (this.client.connected) {
      const params: IPublishParams = {
        destination: `${SOCKET_URL.CRM.GET_SESSION}`,
      };
      this.client.publish(params);
      this._isConnected$.next(true);
    } else {
      setTimeout(() => {
        this.getSession();
      }, 1000);
      console.warn(
        "Không thể gửi yêu cầu get session. WebSocket chưa được kết nối."
      );
      this._isConnected$.next(false);
    }
  }

  public handleCallHistoryModal(data: any) {
    let loadingModalRef: NgbModalRef;

    if (!loadingModalRef) {
      loadingModalRef = this.modalService.open(WaitingLoadingModalComponent, {
        centered: true,
        backdrop: "static",
      });
    }

    this.isConnected$
      .pipe(
        distinctUntilChanged(),
        filter((connect) => connect),
        take(1)
      )
      .subscribe(() => {
        loadingModalRef?.close();

        const modalRef = this.modalService.open(CallHistoryContainerComponent, {
          size: "xxl",
          centered: true,
          backdrop: "static",
        });

        Object.keys(data).forEach((key) => {
          modalRef.componentInstance[key] = data[key];
        });
      });
  }
}
