import {
  AsyncPipe,
  DatePipe,
  NgFor,
  NgIf,
  NgTemplateOutlet,
} from "@angular/common";
import { Component, inject, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { COLUMN_OF_CALL_HISTORY_ORDER } from "@constant/table.constant";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { InvisiblePhoneNumberPipe } from "@pipe/invisible-phone-number.pipe";
import { StateContactHistoryService } from "@service/call-history/state-contact-history.service";
import { combineLatest, Observable } from "rxjs";
import { startWith } from "rxjs/operators";

@Component({
  selector: "call-history-order-list",
  templateUrl: "./call-history-order-list.component.html",
  styleUrls: ["./call-history-order-list.component.scss"],
  standalone: true,
  imports: [
    NgbTooltipModule,
    InvisiblePhoneNumberPipe,
    AsyncPipe,
    DatePipe,
    NgFor,
    NgIf,
    NgTemplateOutlet,
  ],
  providers: [StateContactHistoryService],
})
export class CallHistoryOrderListComponent implements OnInit {
  public stateContactHistory = inject(StateContactHistoryService);

  readonly COLUMNS = COLUMN_OF_CALL_HISTORY_ORDER;

  @Input() callHistoryForm: FormGroup;

  ngOnInit(): void {
    this.loadContactHistories();
  }

  get controls() {
    return this.callHistoryForm.controls;
  }

  public loadContactHistories() {
    combineLatest([
      this.getChangeOfControl("order"),
      this.getChangeOfControl("brand"),
    ]).subscribe(([order, _]) => {
      this.stateContactHistory.historyCallOrder(order);
    });
  }

  private getChangeOfControl(control: string): Observable<any> {
    return this.controls[control].valueChanges.pipe(
      startWith(this.controls[control].value)
    );
  }
}
