import { Injectable } from "@angular/core";
import { BaseHttpRequest } from "../http/base-http-request.service";
import { Observable } from "rxjs";
import { API_URL } from "@constant/api-url.constant";
import {
  CallHistoryInfoModel,
  CallToRealEstateOwnerPayload,
} from "@model/call-history-info.model";

@Injectable({
  providedIn: "root",
})
export class HttpHistoryCallService extends BaseHttpRequest {
  // Thêm mới BĐS | Add new a RealEstate
  saveCall(payload: CallHistoryInfoModel): Observable<any> {
    return this.post(API_URL.CONTACT_HISTORY, payload);
  }

  search(params: ContactSearchParams): Observable<any> {
    return this.get(`${API_URL.OWNER_CONTACT_HISTORY}/${params?.realEstateId}/records`, {
      params: {
        ...params,
      },
    });
  }

  saveCallToRealEstateOwner(
    payload: CallToRealEstateOwnerPayload
  ): Observable<any> {
    return this.post(
      `${API_URL.OWNER_CONTACT_HISTORY}/${payload?.realEstateId}/records`,
      payload
    );
  }
}

export interface ContactSearchParams {
  realEstateId: number;
  limit: number;
  page: number;
}
