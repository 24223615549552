export const APP_URL = {
  //  AUTH
  LOGIN: "/auth/login",

  //  REAL ESTATE
  REAL_ESTATE_MANAGEMENT: "/real-estate-management",
  REAL_ESTATE_DETAIL: "/real-estate-management/detail",

  //  USER MANAGEMENT
  USER_MANAGEMENT: "/user-management",
  CUSTOMER_DETAIL: "/crm/detail",
  BRAND_DETAIL: "/crm/brands",
  AGENT_DETAIL: "/user-management/agents",

  //GROUP
  GROUP_MANAGEMENT: "/user-management/groups",
  GROUP_DETAIL: "/user-management/groups/detail",
  GROUP_CREATION: "/user-management/groups/creation",

  //EMPLOYEE
  EMPLOYEE_MANAGEMENT: "/user-management/employees",
  EMPLOYEE_DETAIL: "/user-management/employees/detail",
  EMPLOYEE_CREATION: "/user-management/employees/creation",
  EMPLOYEE_EDIT: "/user-management/employees/edit",
};
