import { NgIf } from "@angular/common";
import { Component, inject, Injector, Input } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { NgbActiveModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { CALL_METHODS } from "src/app/public/constants/call-method.constant";
import { REAL_ESTATE_STATUS } from "src/app/public/constants/real-estate.constant";
import { STATUS_CALL } from "src/app/public/constants/status-call.constant";
import { RealEstateModel } from "@model/real-estate.model";
import { HttpHistoryCallService } from "@service/call-history/http-history-call.service";
import { ConvertDateTimeToString } from "@util/date-time.util";
import { FormCallHistoryInfoHelper } from "./call-history-form.helper";
import { FEE_TYPE } from "@constant/common.constant";
import { FlatpickrModule } from "angularx-flatpickr";
import Vietnam from "flatpickr/dist/l10n/vn";
import { TransformPriceToStringPipe } from "@pipe/transform-price-to-string.pipe";
import { CallHistoryInfoModel } from "@model/call-history-info.model";

@Component({
  selector: "call-history-form-component",
  templateUrl: "./home-call-insight.component.html",
  styleUrls: ["./home-call-insight.component.scss"],
  standalone: true,
  providers: [FormCallHistoryInfoHelper, HttpHistoryCallService],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgIf,
    NgbTooltipModule,
    FlatpickrModule,
    TransformPriceToStringPipe,
  ],
})
export class HomeCallInsightComponent {
  // Inject Services
  private modalService = inject(NgbActiveModal);
  // private formHelper = inject(FormCallHistoryInfoHelper);
  private contactHistoryService = inject(HttpHistoryCallService);
  private injector = inject(Injector);
  // Inject variable
  @Input() formData: FormGroup;

  //
  @Input() set realEstate(data: RealEstateModel) {
    if (data != null) {
      // this.formHelper.patchData = data;
    }
  }

  submitForm() {
    this.updateTime();

    if (this.formData.valid) {
      this.contactHistoryService
        .saveCall(this.formData.getRawValue())
        .subscribe((_) => {
          this.modalService.close();
        });
    }
  }

  get controls() {
    return this.formData.controls;
  }

  private updateTime(): void {
    this.formData.patchValue({
      endTime: ConvertDateTimeToString(new Date(), this.injector),
    });
  }

  onClickCancel($event: MouseEvent): void {
    this.modalService.close();
  }

  protected readonly CALL_METHODS = CALL_METHODS;
  protected readonly STATUS_CALL = STATUS_CALL;
  protected readonly REAL_ESTATE_STATUS = REAL_ESTATE_STATUS;
  protected readonly FEE_TYPE = FEE_TYPE;
  public language: any = Vietnam.vn;

  getControl(control: string) {
    return this.formData.controls[control] as UntypedFormControl;
  }
}
