import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { API_URL } from "../../constants/api-url.constant";
import {
  CallHistoryInfoModel,
  CallHistoryOrderModel,
} from "../../models/call-history-info.model";
import { BaseHttpRequest } from "../http/base-http-request.service";

@Injectable({
  providedIn: "root",
})
export class HttpContactHistoryService extends BaseHttpRequest {
  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);

  get total$() {
    return this._total.asObservable();
  }

  get page$() {
    return this._page.asObservable();
  }

  get pageSize$() {
    return this._pageSize.asObservable();
  }

  get limit$() {
    return this._limit.asObservable();
  }

  // Thêm mới BĐS | Add new a RealEstate
  saveCall(payload: CallHistoryInfoModel): Observable<any> {
    return this.post(API_URL.CONTACT_HISTORY, payload);
  }

  search(params: ContactSearchParams): Observable<any> {
    return this.get(`${API_URL.CRM}/${API_URL.LOG_CALLS_REAL_ESTATE}/${params?.realEstateId}/${API_URL.RECORDS}`, {
      params: {
        ...params,
      },
    });
  }

  createHistoryCallOrder(orderId: number, payload: CallHistoryOrderModel) {
    return this.post(
      `${API_URL.CRM}/${API_URL.LOG_CALLS}/${API_URL.ORDER}/${orderId}/${API_URL.RECORDS}`,
      payload
    );
  }

  getHistoryCallOrder(
    orderId: number,
    params: ContactOrderSearchParams
  ): Observable<any> {
    const { page } = params;
    return this.get(
      `${API_URL.CRM}/${API_URL.LOG_CALLS}/${API_URL.ORDER}/${orderId}/${API_URL.RECORDS}`,
      {
        params: { ...params, page: Number(page - 1) },
      }
    );
  }
}

export interface ContactSearchParams {
  realEstateId: number;
  limit: number;
  page: number;
}

export interface ContactOrderSearchParams {
  type?: number;
  active?: boolean;
  limit?: number;
  page?: number;
}
