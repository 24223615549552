import { inject, Injectable } from "@angular/core";
import { REQUEST_DEFAULT } from "@constant/http-request.constant";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
  CallHistoryOrderModel,
  ContactHistoryModel,
} from "@model/call-history-info.model";
import {
  ContactSearchParams,
  HttpHistoryCallService,
} from "src/app/public/services/call-history/http-history-call.service";
import { tap } from "rxjs/operators";
import { HttpContactHistoryService } from "@service/call-history/http-contact-history.service";


@Injectable()
export class StateContactHistoryService {
  private contactService = inject(HttpContactHistoryService);

  private _contactHistories$ = new BehaviorSubject<ContactHistoryModel[]>(null);
  private _isLoading$ = new BehaviorSubject<Boolean>(null);

  private _historyCallOrders$ = new BehaviorSubject<CallHistoryOrderModel[]>(
    null
  );

  get isLoading$(): Observable<Boolean> {
    return this._isLoading$.asObservable();
  }

  get contactHistories$(): Observable<ContactHistoryModel[]> {
    return this._contactHistories$.asObservable();
  }

  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);

  get historyCallOrders$() {
    return this._historyCallOrders$;
  }

  public getContactHistories(params: ContactSearchParams) {
    this._isLoading$.next(true);
    this.contactService
      .search(params)
      .pipe(tap((_) => this._isLoading$.next(false)))
      .subscribe((response) => {
        this._contactHistories$.next(response?.data);
      });
  }

  public historyCallOrder(orderId: number) {
    if (!orderId) {
      this._contactHistories$.next([]);

      return of([]);
    }

    const params = {
      page: REQUEST_DEFAULT.PAGE,
      limit: REQUEST_DEFAULT.SMALL_LIMIT,
    };

    return this.contactService
      .getHistoryCallOrder(orderId, params)
      .pipe(
        tap((data) => {
          this._historyCallOrders$.next(data.data);
        })
      )
      .subscribe();
  }

  public reloadHistoryCallOrder(orderId: number) {
    this.historyCallOrder(orderId);
  }
}
