import { inject, Injectable } from "@angular/core";
import { BrandModel } from "@model/brand.model";
import { BehaviorSubject, Observable } from "rxjs";
import { reduce, tap } from "rxjs/operators";
import { BrandHttpService } from "./brand-http.service";

@Injectable({
  providedIn: "root",
})
export class BrandService {
  private brandHttp = inject(BrandHttpService);

  private _total = new BehaviorSubject<number>(0);
  private _page = new BehaviorSubject<number>(0);
  private _pageSize = new BehaviorSubject<number>(0);
  private _limit = new BehaviorSubject<number>(0);

  get total$() {
    return this._total.asObservable();
  }

  get page$() {
    return this._page.asObservable();
  }

  get pageSize$() {
    return this._pageSize.asObservable();
  }

  get limit$() {
    return this._limit.asObservable();
  }

  public getListBrand(params?: any): Observable<BrandModel[]> {
    return this.brandHttp.getListBrand(params).pipe(
      tap((data: any) => {
        const _total = data?.totalItems;
        const _page = params?.page;
        const _limit = params?.limit;
        const _pageSize = data?.data?.length;

        this._total.next(_total);
        this._page.next(_page);
        this._limit.next(_limit);
        this._pageSize.next(_pageSize);
      })
    );
  }

  public createBrand(payload: BrandModel) {
    return this.brandHttp.createBrand(payload);
  }

  public getDetailBrand(id: number | string): Observable<BrandModel> {
    return this.brandHttp.getDetailBrand(id).pipe(
      reduce((_: any, cur: any) => {
        return { ...cur?.data };
      }, {})
    );
  }

  public updateBrand(id: number, payload: BrandModel) {
    return this.brandHttp.updateBrand(id, payload);
  }

  public deleteBrand(id: number) {
    return this.brandHttp.deleteBrand(id);
  }

  public activeBrand(id: number) {
    return this.brandHttp.activeBrand(id);
  }

  public inactiveBrand(id: number) {
    return this.brandHttp.inactiveBrand(id);
  }
}
