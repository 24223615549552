import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./public/layouts/layout.component";

// Auth
import { ErrorPageComponent } from "src/app/components/errors/404-page/error-page.component";
import { AuthGuard } from "./public/guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    canLoad: [AuthGuard],
    children: [
      {
        path: "",
        canLoad: [AuthGuard],
        pathMatch: "full",
        redirectTo: "dashboard",
      },
      {
        path: "dashboard",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import("./pages/home/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "real-estate-management",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import(
            "./pages/real-estate-management/real-estate-management.module"
          ).then((m) => m.RealEstateManagementModule),
      },
      {
        path: "user-management",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import("./pages/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: "crm",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import("@page/crm/crm.module").then((m) => m.CrmModule),
      },
      {
        path: "message-management",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import("./pages/message-management/message-management.module").then(
            (m) => m.MessageManagementModule
          ),
      },
      {
        path: "configuration",
        canLoad: [AuthGuard],
        loadChildren: () =>
          import(
            "./pages/permission-management/permission-management.module"
          ).then((m) => m.PermissionManagementModule),
      },
    ],
  },
  /**
   * @module: authentication
   */
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/account/account.module").then((m) => m.AccountModule),
  },
  /**
   * Page error
   */
  { path: "404", component: ErrorPageComponent },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
