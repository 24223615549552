import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})export class MapState {
  private route = inject(ActivatedRoute);

  private _polygon$ = new BehaviorSubject(null);
  private _boundaries$ = new BehaviorSubject(null);
  private _allowCallApi$ = new BehaviorSubject<boolean>(true);
  private _showFullMap$ = new BehaviorSubject<boolean>(true);
  private _params$ = new BehaviorSubject(null);

  get polygon$(): Observable<any> {
    return this._polygon$.asObservable();
  }

  set polygon$(value: any) {
    this.allowCallApi$ = false;
    this._polygon$.next(value);
  }

  get boundaries$(): Observable<any> {
    return this._boundaries$.asObservable();
  }

  set boundaries$(value: any) {
    this.allowCallApi$ = false;
    this._boundaries$.next(value);
  }

  get allowCallApi$(): Observable<boolean> {
    return this._allowCallApi$.asObservable();
  }

  set allowCallApi$(value: any) {
    const currentState = this._allowCallApi$.value;
    if (currentState !== value) this._allowCallApi$.next(value);
  }

  get snapshotAllowCallApi(): boolean {
    return this._allowCallApi$.value;
  }

  get showFullMap$(): Observable<boolean> {
    return this._showFullMap$.asObservable();
  }

  set showFullMap$(value: any) {
    this._showFullMap$.next(value);
  }

  get params$(): Observable<any> {
    if (this._params$.value == null) {
      this._params$.next(this.route.snapshot.queryParams);
    }
    return this._params$.asObservable();
  }

  set params$(value: any) {
    this._params$.next(value == null ? this.route.snapshot.queryParams : value);
  }
}
