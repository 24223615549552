import { Injectable } from "@angular/core";
import { API_URL } from "@constant/api-url.constant";
import { OrderModel } from "@model/order.model";
import { BaseHttpRequest } from "@service/http/base-http-request.service";

@Injectable({
  providedIn: "root",
})
export class OrderHttpService extends BaseHttpRequest {
  public getCustomerOrders(brandId: number, params: any) {
    return this.get(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}`,
      {
        params: { ...params, page: Number(params?.page - 1) },
      }
    );
  }

  public getDetailCustomerOrder(brandId: number, orderId: number) {
    return this.get(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}/${orderId}`
    );
  }

  public createCustomerOrder(payload: OrderModel, brandId: string | number) {
    return this.post(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}`,
      payload
    );
  }

  public updateCustomerOrder(
    payload: OrderModel,
    brandId: number,
    orderId: number
  ) {
    return this.patch(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}/${orderId}`,
      payload
    );
  }

  public deleteCustomerOrder(brandId: number, orderId: number) {
    return this.delete(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}/${orderId}`
    );
  }

  public deleteOrderAddress(
    brandId: number,
    orderId: number,
    addressId: number
  ) {
    return this.delete(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}/${orderId}/addresses/${addressId}`
    );
  }

  public activeOrder(brandId: number, orderId: number) {
    return this.patch(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}/${orderId}/${API_URL.ACTIVE}`,
      {}
    );
  }

  public inactiveOrder(brandId: number, orderId: number) {
    return this.patch(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}/${orderId}/${API_URL.INACTIVE}`,
      {}
    );
  }

  public activeOrderAddress(
    brandId: number,
    orderId: number,
    addressId: number
  ) {
    return this.patch(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}/${orderId}/${API_URL.ADDRESS}/${addressId}/${API_URL.ACTIVE}`,
      {}
    );
  }

  public inactiveOrderAddress(
    brandId: number,
    orderId: number,
    addressId: number
  ) {
    return this.patch(
      `${API_URL.CRM}/${API_URL.ADMIN_PORTAL}/${API_URL.BRANDS}/${brandId}/${API_URL.ORDERS}/${orderId}/${API_URL.ADDRESS}/${addressId}/${API_URL.INACTIVE}`,
      {}
    );
  }
}
