import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthorizationService } from "src/app/public/services/auth/authorization.service";

@Directive({
  standalone: true,
  selector: "[hasPermissionService]",
})
export class CheckPermissionServiceDirective {
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authorizationService: AuthorizationService
  ) {}

  // @ts-ignore
  @Input() set hasPermissionService(resource?: string) {
    if (this.authorizationService.hasPermissionService(resource)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
