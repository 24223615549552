<div class="modal-content">
  <div class="modal-header justify-content-center">
    <lord-icon
      trigger="loop"
      src="https://cdn.lordicon.com/mfblariy.json"
      colors="primary:#405189, secondary: #08a88a"
      style="width: 150px; height: 150px; border: none"
    ></lord-icon>
  </div>

  <div class="modal-body text-center">
    <h4>Vui lòng đợi trong giây lát, hệ thống đang kết nối với tổng đài</h4>
  </div>

  <div class="modal-footer justify-content-center">
    <button
      type="button"
      class="btn btn-danger d-flex align-items-center"
      (click)="activeModal.close()"
    >
      <i class="ri-close-circle-fill fs-16 me-2"></i> <span>Huỷ bỏ</span>
    </button>
  </div>
</div>
