<div
  class="modal-header justify-content-end"
  *ngIf="hasClose"
  xmlns="http://www.w3.org/1999/html"
>
  <button
    type="button"
    data-dismiss="modal"
    aria-label="Close"
    class="close m-0 fs-16"
    (click)="closeModal()"
  >
    <span>×</span>
  </button>
</div>
<div class="modal-body text-center p-5">
  <div>
    <i class="las la-exclamation-circle text-warning fs-48"></i>
    <h4 class="mb-3">{{ title }}</h4>
    <p class="text-muted mb-4">
      {{ content }}
    </p>
    <div class="hstack gap-2 justify-content-center">
      <button class="btn btn-success text-white" (click)="confirmModal(true)">
        <i class="las la-check align-middle"></i>{{ btnConfirmTitle }}
      </button>

      <button
        class="btn btn-outline-danger"
        data-bs-dismiss="modal"
        (click)="confirmModal(false)"
      >
        <i class="ri-close-line me-1 align-middle"></i> {{ btnRejectTitle }}
      </button>
    </div>
  </div>
</div>
