import { Directive, HostListener } from "@angular/core";

@Directive({
  standalone: true,
  selector: "[appOnlyPhoneNumber]",
})
export class OnlyPhoneNumberDirective {
  public MAX_LENGTH = 10;

  @HostListener("keypress", ["$event"]) onInputChange(e: any) {
    const verified = String.fromCharCode(e.which).match(/[^0-9]/g);
    if (verified || e.target.value?.length === this.MAX_LENGTH) {
      e.preventDefault();
      return false;
    }

    return true;
  }
}
