import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "transformPriceToString",
  standalone: true,
})
export class TransformPriceToStringPipe implements PipeTransform {
  constructor() {}

  transform(value: number, currency: string = "VND"): string | number {
    if (value !== null && Number(value)) {
      const length = value?.toString()?.length;

      if (length > 9) {
        return (value / 1000000000)
          .toFixed(2)
          .replace(/\.0+$/, "")
          .toString()
          .replace(".", ",")
          .concat(" tỷ");
      } else if (length > 6) {
        return (value / 1000000)
          .toFixed(2)
          .toString()
          .replace(".", ",")
          .concat(" triệu");
      } else {
        // return new Intl.NumberFormat("en-US", {
        //   style: "currency",
        //   currency: currency,
        // }).format(value);
        return value;
      }
    }
    return "--";
  }
}
